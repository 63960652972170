.doctor-card{
    background: white;
    box-shadow: 0px 6px 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 1.5rem 0.5rem 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 40vw;
    min-width: 350px;
    margin: 2rem 1rem;
    height: 180px;
}
.doctor-card-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.doctor-card-content h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: clamp(1.1rem,1.6rem,2vw);
    line-height: 1.8rem;
    color: #222222;
    margin: 1rem 0 0.5rem;
}
.doctor-card-content h3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: clamp(0.9rem,1rem,1.5vw);
    line-height: 1.2rem;
    color: #222222;
    margin-bottom: 0.5rem;
}
.doctor-card-content p{
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    font-size: clamp(0.7rem,0.8rem,1.5vw);
    line-height: 1rem;
    color: #222222;
}
.doctor-card img{
    height: clamp(170px,230px,20vw);
    margin-bottom: -1.5rem;
}
@media screen and (max-width: 800px){
    .doctor-card{
        width: 80vw;
    }
    .doctor-card img{
        height: clamp(170px,230px,35vw);
    }
    .doctor-card-content p{
        font-size: clamp(0.7rem,0.8rem,3vw);
    }
    .doctor-card-content h3{
        font-size: clamp(0.9rem,1rem,3vw);
    }
    .doctor-card-content h2{
        font-size: clamp(1.1rem,1.6rem,4vw);
    }
}