.home-intro{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-image: url('../../../public/background-svg.svg');
    background-position: 50% 70%;
    padding: 3rem 0;
}
.home-intro img{
    width: 40%;
    max-width: 400px;
    min-width: 300px;
}
.home-back-vector{
    position: absolute;
    z-index: -1;
    width: 98.9vw;
    bottom: -1vh;
    left: 0;
}
.home-intro-about{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40vw;
    min-width: 300px;
    padding: 0 2rem;
}
.home-intro-about p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    color: #FFFFFF;
    line-height: 1.85em;
    margin-bottom: 2rem;
}
@media screen and (max-width: 650px) {
    .home-intro{
        flex-direction: column;
        justify-content: center;
    }
    .home-intro-about{
        align-items: center;
        width: 80vw;
    }
    .home-intro-about p{
        text-align: center;
    }
    .home-intro img{
        margin-top: 3rem;
    }
}
.home-benifits{
    margin: 6rem 4rem;
}
.home-benefits-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.home-benefits-heading h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(2.2rem,3rem,6vw);
    line-height: 3.5rem;
    color: #000000;
}
.home-benifits-content-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2rem 0rem;
    flex-wrap: wrap;
}
.home-benifits-content{
    position: relative;
    width: 20%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem;
    height: 40vh;
    max-width: 450px;
}
.home-benifits-content h3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
    color: #000000;
}
.home-benifits-content p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #000000;
    text-align: justify;
    margin: 1.3rem 0;
}
.home-benefits-para{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: clamp(1rem,1.5rem,4vw);
    line-height: 2rem;
    text-align: center;
    color: #000000;
    margin: 8rem 1rem 2rem;
}
@media screen and (max-width: 1050px) {
    .home-benifits{
        margin: 6rem 1.5rem;
    }
    .home-benifits-content{
        min-width: 300px;
        margin-bottom: 3rem;
        height: auto;
    }
    .home-benefits-heading{
        flex-direction: column;
    }
    .home-benefits-heading h1{
        margin-bottom: 2rem;
        text-align: center;
    }
    .home-benefits-para{
        margin: 0;
    }
}
.home-services{
    /* background: url('../../../public/background-grey.svg'); */
    margin: 3rem 4rem;
}
.home-services-content-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 3rem;
}
.home-services-content{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3.5rem 0;
    min-width: 450px;
}
.home-services-content img{
    height: 80px;
}
.home-services-content:nth-of-type(2n){
    flex-direction: row-reverse;
}
.home-services-content:nth-of-type(2n+1){
    flex-direction: row;
}
.home-services-content:nth-of-type(2n) img{
    margin-left: 3rem;
}
.home-services-content:nth-of-type(2n+1) img{
    margin-right: 3rem;
}
.home-services-content h3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.7rem;
    color: #000000;
    margin: 1rem 0;
}
.home-services-content p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2rem;
    color: #000000;
}
.home-back-grey-vector{
    position: absolute;
    bottom: -46rem;
    z-index: -2;
    width: 98vw;
    left: 0;
}
@media screen and (max-width: 1050px) {
    .home-services-content-container{
        justify-content: center;
    }
    .home-services-content{
        max-width: 800px;
        width: 80%;
        min-width: 300px;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width: 500px) {
    .home-services-content:nth-of-type(n){
        text-align: center;
        flex-direction: column-reverse;
    }
    .home-services-content:nth-of-type(n) img{
        margin: 0;
        margin-top: 2rem;
    }
    .home-services{
        margin: 3rem 1.5rem;
    }
    .home-services-content{
        width: 95%;
    }
}