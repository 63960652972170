.university-content-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.university-heading{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    background: url('../../../public/university-background.png');
    width: 100%;
}
.university-heading h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(2.5rem,3.5rem,1vw);
    line-height: 4rem;
    color: #000000;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    text-align: center;
}
.university-button-container{
    padding: 1.5rem 0rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #FFF;
    flex-wrap: wrap;
}
.university-button-container h3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #000000;
    margin: 0 1.5rem 0.5rem;
    cursor: pointer;
    border: 1px solid #000000;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    text-align: center;
}
.university-content-main{
    padding: 2rem 3rem;
    position: relative;
}
@media screen and (max-width: 900px) {
    .university-content-main{
        align-items: center !important;
        text-align: center !important;
        padding: 2rem 1.5rem;
    }
}
@media screen and (min-width: 900px) {
    .university-button-container img{
        display: none;
    }
    .university-buttons{
        justify-content: center;
    }
}
@media screen and (max-width: 400px) {
    .university-button-container h3{
        max-width: 150px;
        margin: 0.5rem;
    }
}