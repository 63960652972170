*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.navbar-main{
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #14684e;
    width: 100vw;
    z-index: 5;
}
.navbar-container{
    height: 120px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}
.navbar-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.navbar-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0.7rem;
}
.navbar-icons{
    color: white;
    height: 24px;
}
.navbar-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.navbar-img{
    height: clamp(4.3rem,5rem,7vw);
}
.navbar-head-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(1.5rem,1.8rem,3vw);
    color: #FFFFFF;
    margin: 0;
}
.navbar-ham{
    height: 40px;
    cursor: pointer;
}
.navbar-ham-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 900px) {
    .navbar-buttons{
        display: none;
    }
}
@media screen and (min-width: 900px) {
    .navbar-ham{
        display: none;
    }
    .navbar-ham-buttons{
        display: none !important;
    }
    
}