*{
    box-sizing: border-box;
}
.university-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.university-scroll{
    display: flex;
    flex-direction: column ;
    align-items: center;
    justify-content: flex-start;
    width: 270px;
    flex-shrink: 0;
    background: #FFFFFF;
    box-shadow: 5px 4px 500px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    padding: 1rem;
    cursor: pointer;
}
.university-scroll::-webkit-scrollbar {
    display: none;
}
.university-scroll h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 1.8rem;
    color: #000000;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}
.university-scroll ul li{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.6rem;
    color: #000000;
    margin-left: 2rem;
    margin-bottom: 0.8rem;
}
.university-content{
    flex-grow: 1;
}
.browse-universities{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    width: 100vw;
    padding: 0 1.5rem;
    cursor: pointer;
    z-index: 100;
    background: #FFFFFF;
}
.browse-universities h3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: #000000;
}
.university-ham{
    cursor: pointer;
    height: 35px;
}
.university-sidebar{
    display: flex;
    flex-direction: column ;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 50vh;
    overflow-y: scroll;
    flex-shrink: 0;
    background: #FFFFFF;
    box-shadow: 5px 4px 500px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    padding: 1rem;
    position: absolute;
    left: 0;
    top: 190px;
}
.university-sidebar h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 1.8rem;
    color: #000000;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}
.university-sidebar ul li{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.6rem;
    color: #000000;
    margin-left: 2rem;
    margin-bottom: 0.8rem;
    cursor: pointer;
}
@media screen and (max-width: 900px) {
    .university-scroll{
        display: none;
    }
}
@media screen and (min-width: 900px) {
    .university-scroll{
        display: flex;
    }
    .university-sidebar{
        display: none !important;
    }
    .navbar-university-ham{
        display: none !important;
    }
    .browse-universities{
        display: none !important;
    }
}