.footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    width: 100vw;
    background: #000000;
    padding: 3rem 0;
}
.footer-logo{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.footer-img{
    height: 5rem;
}
.footer-head-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    color: #FFFFFF;
    margin: 0;
}
.footer-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.footer-section a{
    cursor: pointer;
    text-decoration: none;
}
.footer-head{
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 39px;
}
.footer-link{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #FFFFFF;
    margin-top: 0.35rem;
}
.footer-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.footer-icon{
    margin-right: 0.5rem;
    height: 1.2rem;
}
.footer-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 45vw;
}
@media screen and (max-width: 600px) {
    .footer{
        flex-direction: column;
        justify-content: center;
    }
    .footer-section{
        align-items: center;
    }
    .footer-content{
        margin-top: 3rem;
        width: 80vw;
    }
}