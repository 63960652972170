.university-fees-container{
    display: flex;
    flex-direction: column;
}
.university-fees-container h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.7rem;
    color: #000000;
    margin: 1.5rem 0;
}
.university-fees-container table {  
    align-self: center;
    margin: 1.5rem 0;
}
.university-fees-container table th{
    padding: .25rem .5rem;
    background-color: #147B5C;
    color:#fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.2rem;
    justify-content: center;
    padding: 0.8rem 2.5rem;
    margin: 0.03rem 0.5em;
}
.university-fees-container table td{
    padding: .25rem .5rem;
    text-align: center;
    background-color:#D9D9D9;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.2rem;
    text-align: center;
    color: #000000;
    padding: 0.8rem 2rem;
}
.university-fees-container p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.05rem;
    line-height: 2rem;
    color: #000000;
}
.university-fees-container li{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.05rem;
    line-height: 2.5rem;
    color: #000000;
}
.university-academics-container ul{
    list-style-position: inside;
}
.university-academics-container ol{
    list-style-position: inside;
}