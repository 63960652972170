.profile {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin: 5rem 0;
}
.form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    width: 100vw;
    justify-content: center;
}
.form input {
    height: 50px;
    margin-top: 20px;
    border-radius: 10px;
    padding: 1rem ;
    width: 40vw;
    outline: none;
    border: 1px solid black;
    margin: 1rem 2rem;
    max-width: 450px;
}
.submit-button{
    width: 40vw;
    background-color: #1e8364;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 10px;
    max-width: 450px;
    margin-top: 2rem;
    cursor: pointer;
}
.profile-submitted{
    height: 495px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-submitted h2{
    text-align: center;
    font-size: 3rem;
    color: #000;
    font-family: Montserrat;
    font-weight: 500;
}
@media screen and (max-width: 750px) {
    .form input{
        width: 80vw;
    }
    .submit-button{
        width: 80vw;
    }
}
  
