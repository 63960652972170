@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-21xl: 2.5rem;
  --font-size-9xl: 1.75rem;
  --font-size-5xl: 1.5rem;
  --font-size-53xl: 4.5rem;
  --font-size-13xl: 2rem;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;

  /* border radiuses */
  --br-xl: 20px;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.navbar-space-fill{
  height: 120px;
}

*::-webkit-scrollbar {
  display: none;
}