.doctor-back{
    position: absolute;
    z-index: -1;
    width: 100vw;
    bottom: -1vh;
}
.doctor-heading h1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: clamp(3.8rem,6rem,10vw);
    line-height: 7rem;
    text-align: center;
    color: #FFFFFF;
}
.doctor-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    margin-bottom: 5rem;
    background: linear-gradient(295.63deg, #61BC9B 14.4%, #137A5B 52.91%);
}
.doctor-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10rem;
}
.doctor-back-art{
    position: absolute;
    width: 95vw;
    bottom: -38rem;
    left: -5rem;
}